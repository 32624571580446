import React, { useEffect } from "react"
import styled from "styled-components"

// import ReactGA from "react-ga"
// ReactGA.initialize(process.env.GOOGLE_TRACKING_ID)

// ReactGA.event({
//   category: "converstion",
//   action: "redirect to payment",
// })

import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const StyledRedirect = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 9999;
  background: white;
`

const Online = () => {
  useEffect(() => {
    trackCustomEvent({
      action: "Redirect to fitogram",
      category: "Redirect Link",
    })

    window.location.href = process.env.REDIRECT_LINK
  })

  return <StyledRedirect />
}

export default Online
